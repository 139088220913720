var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"width":_vm.modalSize,"scroll":"keep","can-cancel":false},model:{value:(_vm.openDialogUpload),callback:function ($$v) {_vm.openDialogUpload=$$v},expression:"openDialogUpload"}},[_c('div',{staticClass:"card"},[_c('b-loading',{attrs:{"is-full-page":false,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_c('div',{staticClass:"card-head"},[_c('div',{staticClass:"is-flex pt-3",class:{
				'is-justify-content-space-between': !_vm.isMobile,
				'is-flex-direction-column-reverse': _vm.isMobile
			}},[_c('div',{staticClass:"column is-6-tablet is-4-desktop is-3-widescreen is-10-mobile is-offset-1-mobile",class:{
					'mt-5 ml-6': !_vm.isMobile,
				}},[_c('div',{staticClass:"is-flex is-justify-content-space-around pb-2"},[_c('span',{class:[{ 'progress-label-active': _vm.getActiveStep == 1 }, 'is-size-7 disable-text']},[_vm._v("Etapa 1")]),_c('span',{class:[{ 'progress-label-active': _vm.getActiveStep == 2 }, 'is-size-7 disable-text']},[_vm._v("Etapa 2")]),_c('span',{class:[{ 'progress-label-active': _vm.getActiveStep == 3 }, 'is-size-7 disable-text']},[_vm._v("Etapa 3")]),_c('span',{class:[{ 'progress-label-active': _vm.getActiveStep == 4 }, 'is-size-7 disable-text']},[_vm._v("Etapa 4")])]),_c('b-progress',{attrs:{"type":"is-success","value":_vm.getProgress,"size":"is-small"}})],1),_c('div',{staticClass:"column is-1-desktop is-1-tablet is-11-mobile",class:{
					'is-flex is-justify-content-flex-end': _vm.isMobile,
					'is-flex is-justify-content-center': !_vm.isMobile
				}},[_c('span',{staticClass:"is-size-3 is-clickable",on:{"click":_vm.handleCloseModal}},[_vm._v("X")])])])]),_c('div',{staticClass:"box-etapas"},[_c('input',{ref:"uploader",staticClass:"is-hidden",attrs:{"type":"file","multiple":""},on:{"input":_vm.enviarDocumento}}),(_vm.getActiveStep == 1)?_c('Etapa1Upload',{attrs:{"data":_vm.getEstruturaModalEnvioDocumentos.firstStep ? _vm.getEstruturaModalEnvioDocumentos.firstStep : { text: '', types: null }},on:{"setType":function($event){_vm.type = $event},"moveStep":_vm.moveStep,"handleCloseModal":_vm.handleCloseModal}}):_vm._e(),(_vm.getActiveStep == 2 || _vm.getActiveStep == 3)?_c('Etapa2e3Upload',{attrs:{"data":_vm.getEstruturaModalEnvioDocumentos.secondStep || {},"documento-upload":_vm.documentoUpload},on:{"moveStep":_vm.moveStep,"handleCloseModal":_vm.handleCloseModal,"onEnviarDocumento":_vm.onEnviarDocumento,"cancelDocument":_vm.handleCancelDocument}}):_vm._e(),(_vm.getActiveStep == 4)?_c('Etapa4Upload',{on:{"moveStep":_vm.moveStep,"handleCloseModal":_vm.handleCloseModal}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }